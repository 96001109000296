@import "@fontsource/source-sans-pro/300.css";
@import "@fontsource/source-sans-pro/400.css";
@import "@fontsource/source-sans-pro/600.css";
@import "@fontsource/source-sans-pro/700.css";

h2, .h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
}

h3, .h3 {
  font-size: 18px;
  line-height: 1.33;
  margin: 0;
}