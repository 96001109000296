.display-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-space-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}