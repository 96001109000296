:root {
  --g-font-family: "Source Sans Pro", sans-serif;
  --g-font-size: 16px;
}

// Palette
:root {
  --g-primary-color: #1F1F1F;
  --g-secondary-color: #F8F8F8;
  --g-accent-color: #00519E;
  --g-error-color: #D9090F;
  --g-warning-color: #FFD600;

  --neutral-darkest-color: #444444;
  --neutral-dark-color: #7A7A7A;
  --neutral-medium-color: #C3C3C3;
  --neutral-light-color: #E0E0E0;
  --neutral-lightest-color: #F2F2F2;

  --g-devider-color: var(--neutral-light-color);
}

// Layout
:root {
  --g-layout-padding-inline: 20px;
}

// Form controls
:root {
  --g-form-control-height: 40px;
  --g-form-control-border: 1px solid var(--g-devider-color);
  --g-form-control-border-radius: 4px;
  --g-select-background-color: #FFFFFF;
}

// Board
:root {
  --g-board-background: #FFFFFF;
  --g-board-padding-inline: 24px;
}